import {gql} from 'graphql-request'
import {
    LINK_FRAGMENT_FOR_LARGE_QUERY,
    MEDIA_FRAGMENT_FOR_LARGE_QUERY,
    THEME_FRAGMENT
} from './fragments'

export const getPageQuery = gql`
    ${THEME_FRAGMENT}
    ${MEDIA_FRAGMENT_FOR_LARGE_QUERY}
    ${LINK_FRAGMENT_FOR_LARGE_QUERY}
    query PageCollection($locale: String!, $slug: String!) {
        pageCollection(where: {slug: $slug}, limit: 1, locale: $locale) {
            items {
                sectionsCollection(limit: 10) {
                    items {
                        ... on HomeHeroSection {
                            sectionName
                            heading
                            subTitle
                            ctaTop {
                                ...LinkFragmentForLargeQueryFragment
                            }
                            ctaBottom {
                                ...LinkFragmentForLargeQueryFragment
                            }
                            media {
                                ...MediaFragmentForLargeQuery
                            }
                            mediaAlignment
                        }

                        ... on TextAsset {
                            name
                            textInAsset
                            subText
                            media {
                                ...MediaFragmentForLargeQuery
                            }
                            text
                        }
                        ... on ContentGrid {
                            sectionName
                            banner {
                                ... on CtaBanner {
                                    title
                                    cta {
                                        ...LinkFragmentForLargeQueryFragment
                                    }
                                    banner {
                                        ...MediaFragmentForLargeQuery
                                    }
                                }
                            }
                            heading
                            subTitle
                            cta {
                                ...LinkFragmentForLargeQueryFragment
                            }
                            itemsCollection(limit: 12) {
                                items {
                                    ... on Article {
                                        cardTitle
                                        cardCta {
                                            ...LinkFragmentForLargeQueryFragment
                                        }
                                        articleCategoriesCollection(limit: 1) {
                                            items {
                                                ... on ArticleCategory {
                                                    name
                                                }
                                            }
                                        }
                                        slug
                                        cardImage {
                                            ...MediaFragmentForLargeQuery
                                        }
                                        cardLongDescription
                                    }
                                    ... on ProductCategory {
                                        name
                                        theme {
                                            ...ThemeFragment
                                        }
                                        cta {
                                            ...LinkFragmentForLargeQueryFragment
                                        }
                                        banner {
                                            ...MediaFragmentForLargeQuery
                                        }
                                    }
                                    ... on Card {
                                        title
                                        subtitle
                                        cta {
                                            ...LinkFragmentForLargeQueryFragment
                                        }
                                        media {
                                            ...MediaFragmentForLargeQuery
                                        }
                                        description
                                    }
                                }
                            }
                        }
                        ... on Carousel {
                            sectionName
                            heading
                            description
                            cta {
                                ...LinkFragmentForLargeQueryFragment
                            }
                            theme {
                                ...ThemeFragment
                            }
                            itemsCollection(limit: 10) {
                                items {
                                    ... on ServiceCard {
                                        image {
                                            ...MediaFragmentForLargeQuery
                                        }
                                        name
                                        heading
                                        headingType
                                        description
                                        cta {
                                            ...LinkFragmentForLargeQueryFragment
                                        }
                                    }
                                    ... on Product {
                                        contentfulTitle
                                        name
                                        description
                                        regularPrice
                                        salePrice
                                        brand {
                                            ... on Brand {
                                                name
                                            }
                                        }
                                        image {
                                            ...MediaFragmentForLargeQuery
                                        }
                                        productId
                                        fallbackProductId
                                        currency
                                    }

                                    ... on Article {
                                        cardTitle
                                        cardImage {
                                            ...MediaFragmentForLargeQuery
                                        }
                                        cardCta {
                                            ...LinkFragmentForLargeQueryFragment
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
